import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { Flex, Divider, View, Text, Heading, Button, SelectField} from '@aws-amplify/ui-react';
import Vehicleinsurance from './vehicleinsurance';
import Vehicleregistration from './vehicleregistration';

export default function Vehicleinfo({userJwt, vehicle, fleetCode, refreshParent, displayAdministrativeFunctions, displaySuperAdministrativeFunctions}) {
  let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [show_update_vin, updateShowUpdateVin] = React.useState(false);
  const [show_update_color, updateShowUpdateColor] = React.useState(false);
  const [color, updateColor] = React.useState("");
  const [show_update_homespot, updateShowUpdateHomespot] = React.useState(false);
  const [show_update_license, updateShowUpdateLicense] = React.useState(false);
  const [show_update_name, updateShowUpdateName] = React.useState(false);
  const [show_update_financing, updateShowUpdateFinancing] = React.useState(false);
  const [show_new_financing_company, updateShowNewFinancingCompany] = React.useState(false);
  const [show_error_handler, updateShowErrorHandler] = React.useState(false);
  const [addresses, updateAddresses] = React.useState([]);
  const [address_id, updateAddressId] = React.useState("");
  const [show_confirm, updateShowConfirm] = React.useState(false);
  const [confirm_text, updateConfirmText] = React.useState("Are you sure?");
  const [confirm_reason, updateConfirmReason] = React.useState("");
  const [name, updateName] = React.useState("");
  const [license, updateLicense] = React.useState("");
  const [license_state, updateLicenseState] = React.useState("");
  const [license_expire, updateLicenseExpire] = React.useState("");
  const [error_message, updateErrorMessage] = React.useState("");
  const [financing_companies, updateFinancingCompanies] = React.useState([]);
  const [financingCompanyId, updateFinancingCompanyId] = React.useState("");
  const [financingCompanyName, updateFinancingCompanyName] = React.useState("");

  
  async function showNewFinancingCompany() {
    updateShowNewFinancingCompany(true);
  }
  async function hideNewFinancingCompany() {
    updateShowNewFinancingCompany(false);
  }
  async function showUpdateVin() {
    updateShowUpdateVin(true);
  }
  async function showUpdateVin() {
    updateShowUpdateVin(true);
  }
  async function hideUpdateVin() {
    updateShowUpdateVin(false);
  }
  async function showUpdateLicense() {
    updateShowUpdateLicense(true);
  }
  async function showUpdateName() {
    updateShowUpdateName(true);
  }
  async function hideUpdateFinancing() {
    updateShowUpdateFinancing(false);
  }
  async function hideUpdateHomespot() {
    updateShowUpdateHomespot(false);
  }
  async function hideUpdateLicense() {
    updateShowUpdateLicense(false);
  }
  async function hideUpdateName() {
    updateShowUpdateName(false);
  }

  async function showUpdateFinancing() {
    updateIsLoading(true);
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"financingcompanies",
        fleet_code:fleetCode,
        request_type:"GET"
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        updateFinancingCompanies(res.resp);
        updateShowUpdateFinancing(true);
        updateIsLoading(false);
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        updateIsLoading(false)
        return
    }
  }

  async function submitNewFinancingCompany() {
    updateIsLoading(true);
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"financingcompanies",
        fleet_code:fleetCode,
        request_type:"POST",
        command:"create",
        financing_company_name:financingCompanyName
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        hideNewFinancingCompany();
        showUpdateFinancing();
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        updateIsLoading(false)
        return
    }
  }

  async function submitVehicleFinancingCompany() {
    updateShowUpdateFinancing(false);
    updateIsLoading(true);
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"financingcompanies",
        fleet_code:fleetCode,
        request_type:"POST",
        command:"assign_vehicle",
        vehicle_id:vehicle['resp'][0]['car_id'],
        financing_company_id:financingCompanyId
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        console.log(JSON.stringify(res))
        refreshParent();
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        updateIsLoading(false)
        return
    }
  }

  async function showUpdateHomespot() {
    updateIsLoading(true);
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"addresses",
        fleet_code:fleetCode,
        request_type:"GET"
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        console.log('addresses'+JSON.stringify(res))
        updateAddresses(res.rows);
        updateShowUpdateHomespot(true);
        updateIsLoading(false);
        return
    }
    catch (e) {
        const res = {status:"Error - email us at info@joinago.com",rows:[]};
        console.log(JSON.stringify(res))
        updateIsLoading(false)
        return
    }
  }
  async function confirmUpdateHomespot()
  {
    hideUpdateHomespot();
    updateIsLoading(true);
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"vehicleupdate",
        vehicle_id: vehicle['resp'][0]['car_id'],
        address_id:parseInt(address_id),
        fleet_code:fleetCode
    }};
    console.log('init: '+JSON.stringify(myInit));
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        console.log(JSON.stringify(res))
        refreshParent();
        updateIsLoading(false)
        return
    }
    catch (e) {
        const res = {status:"Error performing update or user not permissioned - email us at info@joinago.com",rows:[]};
        updateIsLoading(false)
        return
    }
  }
  function cancelUpdateHomespot()
  {
    hideUpdateHomespot();
  }

  async function confirmUpdateLicense()
  {
    hideUpdateLicense();
    updateIsLoading(true);
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"vehicleupdate",
        vehicle_id: vehicle['resp'][0]['car_id'],
        license:license,
        license_expire:license_expire,
        license_state:license_state,
        fleet_code:fleetCode
    }};
    //console.log(JSON.stringify(myInit));
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        console.log(JSON.stringify(res))
        refreshParent()
        updateIsLoading(false)
        return
    }
    catch (e) {
        const res = {status:"Error performing update or user not permissioned - email us at info@joinago.com",rows:[]};
        updateIsLoading(false)
        return
    }
  }
  
  async function confirmUpdateName()
  {
    hideUpdateName();
    updateIsLoading(true);
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"vehicleupdate",
        vehicle_id: vehicle['resp'][0]['car_id'],
        new_name:name,
        fleet_code:fleetCode
    }};
    console.log(JSON.stringify(myInit));
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        refreshParent()
        updateIsLoading(false)
        return
    }
    catch (e) {
        const res = {status:"Error performing update or user not permissioned - email us at info@joinago.com",rows:[]};
        updateIsLoading(false)
        return
    }
  }
  
  function cancelUpdateLicense()
  {
    hideUpdateLicense();
  }
  
  function cancelUpdateName()
  {
    hideUpdateName();
  }

  async function hideErrorHandler() {
    updateShowErrorHandler(false);
  }

  async function showErrorHandler() {
    updateShowErrorHandler(true);
  }
  /*
  async function confirmUpdateColor()
  {
    updateShowUpdateColor(false);
    hideUpdateName();
    updateIsLoading(true);
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"vehicleupdate",
        vehicle_id: vehicle['resp'][0]['car_id'],
        update_type:"color",
        new_color:color
    }};
    //console.log(JSON.stringify(myInit));
    if(color!=='')
    {
      try {
          const res = await API.get('adminWebTools','/ago',myInit)
          console.log(JSON.stringify(res))
          refreshParent()
          updateIsLoading(false)
          return
      }
      catch (e) {
          const res = {status:"Error performing update or user not permissioned - email us at info@joinago.com",rows:[]};
          updateIsLoading(false)
          return
      }
    } else {
      updateIsLoading(false)
      console.log('error - no color submitted')
    }
  }
  */
/*
  function cancelUpdateColor()
  {
    updateShowUpdateColor(false);
  }
*/
  function confirmUpdateVin()
  {
    hideUpdateVin();
    //  IN DEVELOPMENT - SUBMIT THE UPDATE
  }
  function cancelUpdateVin()
  {
    hideUpdateVin();
  }

  function cancelConfirmation()
  {
    updateShowConfirm(false);
    updateConfirmText("Are you sure?");
    updateConfirmReason("");
  }

  function hideConfirmation()
  {
    updateShowConfirm(false);
  }
  
  async function showConfirm(reason) {
    updateShowConfirm(true);
    updateConfirmReason(reason);
    switch(reason)
    {
      case 'vehicleUpdateStatusInactive':
          updateConfirmText("Are you sure you'd like to set this vehicle to Inactive status?");
        break;
      case 'vehicleUpdateStatusActive':
        updateConfirmText("Are you sure you'd like to set this vehicle to Active status?");
        break;
      default:
          updateConfirmText("Error - Invalid Selection");
        break;
    }
  }

  function confirm()
  {
    hideConfirmation();
    switch(confirm_reason)
    {
      case 'vehicleUpdateStatusInactive':
          vehicleUpdateStatusInactive();
        break;
      case 'vehicleUpdateStatusActive':
          vehicleUpdateStatusActive();
        break;
      default:
          updateConfirmText("Error - Invalid Selection");
        break;
    }
  }

  async function vehicleUpdateStatusInactive () {
    updateIsLoading(true)
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"vehicleupdate",
        vehicle_id: vehicle['resp'][0]['car_id'],
        fleet_code:fleetCode,
        new_status:'inactive'
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        refreshParent()
        updateIsLoading(false)
        return
    }
    catch (e) {
        const res = {status:"Error performing update or user not permissioned - email us at info@joinago.com",rows:[]};
        updateIsLoading(false)
        return
    }
  }

  async function vehicleUpdateStatusActive() {
    updateIsLoading(true)
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"vehicleupdate",
        vehicle_id: vehicle['resp'][0]['car_id'],
        new_status:"active",
        fleet_code:fleetCode
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        refreshParent()
        updateIsLoading(false)
        return
    }
    catch (e) {
        const res = {status:"Error performing update or user not permissioned - email us at info@joinago.com",rows:[]};
        updateIsLoading(false)
        return
    }
  }

  const renderAddressSelect = () => {
    if(is_loading){
      return (
        <option value="">loading...</option>
      )
    }
    if(!addresses){
      return (
        <option value="">Error: no addresses found</option>
      )
    }
    if(!addresses[0]){
      return (
        <option value="">Error: no addresses found</option>
      )
    }
    return addresses.map(function(object, index){
        return (
          <option value={object['id']}>{object['state_administrative_area_level_1']} - {object['city_locality']} - {typeof object === 'undefined' ? undefined : typeof object['name'] === 'undefined' ? undefined : typeof object['name'].length !== 'number' ? object['address_full'].slice(0, 10) : object['name'].length > 20 ? object['name'].slice(0,20) + "..." : object['name']} - {object['address_full'].slice(0, 10)}...</option>
        )
    })
  }

  const renderFinancingCompanySelect = () => {
    if(is_loading){
      return (
        <option value="">loading...</option>
      )
    }
    if(!financing_companies[0]){
      return (
        <option value="">Error: no financing companies found</option>
      )
    }
    return financing_companies.map(function(object, index){
        return (
          <option value={object['id']}>{object['name']}</option>
        )
    })
  }


  return (
    is_loading ? <Loadingmini /> : 
    <Flex
      direction="column"
    >
      <View style={{padding:"1rem"}}>
        <Divider />
        {
          vehicle === false ?
            <Flex direction="column">
              <Text>Error loading vehicle</Text>
              <Text>Vehicle not found</Text>
            </Flex>
          :
          vehicle['resp'] === false ?
            <Flex direction="column">
              <Text>Error loading vehicle data</Text>
              <Text>No vehicle data</Text>
            </Flex>
          :
          vehicle['resp'][0] === false ?  
            <Flex direction="column">
              <Text>Error loading vehicle data</Text>
              <Text>No data found for vehicle</Text>
            </Flex>
          :
          typeof(vehicle) === 'undefined' ?  
            <Flex direction="column">
              <Text>Error loading vehicle data</Text>
              <Text>Vehicle ID not found</Text>
            </Flex>
          :
          typeof(vehicle['resp']) === 'undefined' ?  
            <Flex direction="column">
              <Text>Error loading vehicle data</Text>
              <Text>Vehicle ID not found</Text>
            </Flex>
          :
          typeof(vehicle['resp'][0]) === 'undefined' ?  
            <Flex direction="column">
              <Text>Error loading vehicle data</Text>
              <Text>Vehicle ID not found</Text>
            </Flex>
          :
          typeof(vehicle['resp'][0]['car_id']) === 'undefined' ?  
            <Flex direction="column">
              <Text>Error loading vehicle data</Text>
              <Text>Vehicle ID not found</Text>
            </Flex>
          :
            <Flex direction="column">
              <table>
                <tbody>
                    {"car_id" in vehicle['resp'][0] ? 
                    <tr>
                      <th style={{paddingRight:'10px'}}>Vehicle ID:</th>
                      <td>{vehicle['resp'][0]["car_id"]}</td>
                      <td style={{paddingLeft:'15px'}}></td>
                    </tr>
                    : undefined}
                    {"vin" in vehicle['resp'][0] ? 
                    <tr>
                      <th style={{paddingRight:'10px'}}>VIN:</th>
                      <td>{vehicle['resp'][0]["vin"]}</td>
                      <td style={{paddingLeft:'15px'}}>{/*<button onClick={() => showUpdateVin()}>EDIT</button>*/}</td>
                    </tr>
                    : undefined}
                    {"license" in vehicle['resp'][0] ? 
                    <tr>
                      <th style={{paddingRight:'10px'}}>License Plates:</th>
                      <td>{vehicle['resp'][0]["license"]}{"license_state" in vehicle['resp'][0] ? <span> {vehicle['resp'][0]['license_state']}</span> : undefined}{"license_expire" in vehicle['resp'][0] ? vehicle['resp'][0]['license_expire'] !== "" && vehicle['resp'][0]['license_expire'] !== null ? <span style={{fontStyle:'italic'}}> (expires {vehicle['resp'][0]['license_expire']})</span> : undefined : undefined}</td>
                      <td style={{paddingLeft:'15px'}}>{displayAdministrativeFunctions==false?undefined:<button onClick={() => showUpdateLicense()}>EDIT</button>}</td>
                    </tr>
                    : undefined}
                    {"name" in vehicle['resp'][0] ? 
                    <tr>
                      <th style={{paddingRight:'10px'}}>Name:</th>
                      <td>{vehicle['resp'][0]["name"]}</td>
                      <td style={{paddingLeft:'15px'}}>{displayAdministrativeFunctions==false?undefined:<button onClick={() => showUpdateName()}>EDIT</button>}</td>
                    </tr>
                    : undefined}
                    {"car_id" in vehicle['resp'][0] ? 
                    <tr>
                      <th style={{paddingRight:'10px'}}>ID:</th>
                      <td>{vehicle['resp'][0]["car_id"]}</td>
                      <td></td>
                    </tr>
                    : undefined}
                    {"address" in vehicle['resp'][0] ? 
                    <tr>
                      <th style={{paddingRight:'10px'}}>Home:</th>
                      <td>{vehicle['resp'][0]["address"]}</td>
                      <td style={{paddingLeft:'15px'}}>{displayAdministrativeFunctions==false?undefined:<button onClick={() => showUpdateHomespot()}>EDIT</button>}</td>
                    </tr>
                    : undefined}
                    {"color" in vehicle['resp'][0] ? 
                    <tr>
                      <th style={{paddingRight:'10px'}}>Color:</th>
                      <td>{vehicle['resp'][0]["color"]}</td>
                      <td style={{paddingLeft:'15px'}}>{/*<button onClick={() => showUpdateColor()}>EDIT</button>*/}</td>
                    </tr>
                    : undefined}
                    {"make_model_year" in vehicle['resp'][0] ? 
                    <tr>
                      <th style={{paddingRight:'10px'}}>Vehicle Type:</th>
                      <td>{vehicle['resp'][0]["make_model_year"]}</td>
                    </tr>
                    : undefined}
                    {"financing_company_name" in vehicle['resp'][0] ? 
                    <tr>
                      <th style={{paddingRight:'10px'}}>Financing Company:</th>
                      <td>{vehicle['resp'][0]["financing_company_name"]}</td>
                      <td style={{paddingLeft:'15px'}}>{displayAdministrativeFunctions==false ? undefined:
                          <button onClick={() => showUpdateFinancing()}>EDIT</button>}</td>
                    </tr> : 
                        displayAdministrativeFunctions==false ? undefined:
                          <button onClick={() => showUpdateFinancing()}>ADD FINANCING</button>}
                </tbody>
              </table>
              <Vehicleregistration vehicle={vehicle} fleetCode={fleetCode}/>
              <Vehicleinsurance vehicle={vehicle} />
              {
                "address" in vehicle['resp'][0] === false ? 
                <Text><b><i>An Address is required before this vehicle is insured.</i></b></Text>
                :
                vehicle['resp'][0]["address"] !== null && vehicle['resp'][0]["address"] !== "" && vehicle['resp'][0]["address"] !== undefined && vehicle['resp'][0]["address"].length > 0 ? undefined :
                <Text><b><i>An Address is required before this vehicle is insured.</i></b></Text>
              }
              {
                "license" in vehicle['resp'][0] === false ? 
                <Text><i>A License Plate Number and State is required before this vehicle can be set to active status.</i></Text>
                :
                vehicle['resp'][0]["license"] !== null && vehicle['resp'][0]["license"] !== "" && vehicle['resp'][0]["license"] !== undefined && vehicle['resp'][0]["license"].length > 0  ? undefined :
                <Text><i>A License Plate Number and State is required before this vehicle can be set to active status.</i></Text>
              }
              {show_update_homespot ? 
                <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', justifyContent:'center', alignItems:'center', zIndex:100}}>
                  <div style={{position:'fixed', top:'20%', left:'50%',width:'600px',marginLeft:'-300px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                    <h2 style={{textAlign:'center', fontWeight:'bold'}}>Update Homespot</h2>
                    <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>
                      <select name='address_id' onChange={(event) => updateAddressId(event.target.value)}>
                            <option value=''>-PLEASE SELECT-</option>
                            {renderAddressSelect()}
                      </select>
                    </p>
                    <br/>
                    <p style={{textAlign:'center'}}><Button onClick={() => confirmUpdateHomespot()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>SUBMIT</Button></p>
                    <p style={{textAlign:'center'}}><Button onClick={() => cancelUpdateHomespot()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>CANCEL</Button></p>
                  </div>
                </div>
              : undefined}
              {show_update_license ? 
                <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', justifyContent:'center', alignItems:'center', zIndex:100}}>
                  <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                    <h2 style={{textAlign:'center', fontWeight:'bold'}}>Update License Plate</h2>
                    <p style={{textAlign:'center'}}>License plate:<br/><input type='text' onChange={e => updateLicense(e.target.value)} value={license} defaultValue={vehicle['resp'][0]['license']} /></p>
                    <p style={{textAlign:'center'}}>License state:<br/><input type='text' onChange={e => updateLicenseState(e.target.value)} value={license_state} defaultValue={vehicle['resp'][0]['license_state']} /></p>
                    <p style={{textAlign:'center'}}>Expires:<br/><input type='text' onChange={e => updateLicenseExpire(e.target.value)} value={license_expire} defaultValue={vehicle['resp'][0]['license_expire']} /></p>
                    <br/>
                    <p style={{textAlign:'center'}}><Button onClick={() => confirmUpdateLicense()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>SUBMIT</Button></p>
                    <p style={{textAlign:'center'}}><Button onClick={() => cancelUpdateLicense()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>CANCEL</Button></p>
                  </div>
                </div>
              : undefined}
              {show_update_financing ? 
                show_new_financing_company ?
                <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', justifyContent:'center', alignItems:'center', zIndex:100}}>
                  <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                    <h2 style={{textAlign:'center', fontWeight:'bold'}}>Add Financing Company</h2>
                    <p style={{textAlign:'center'}}>Financing Company Name:<br/><input type='text' onChange={e => updateFinancingCompanyName(e.target.value)} value={financingCompanyName} /></p>
                    <br/>
                    <p style={{textAlign:'center'}}><Button onClick={() => submitNewFinancingCompany()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>SUBMIT</Button></p>
                    <br/>
                    <p style={{textAlign:'center'}}><Button onClick={() => hideNewFinancingCompany()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>CANCEL</Button></p>
                  </div>
                </div>
                :
                <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', justifyContent:'center', alignItems:'center', zIndex:100}}>
                  <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                    <h2 style={{textAlign:'center', fontWeight:'bold'}}>Vehicle Financing</h2>
                    <p style={{textAlign:'center'}}>Financing Company:</p>
                    <p style={{textAlign:'center'}}><select name='financing_company' onChange={(event) => updateFinancingCompanyId(event.target.value)}>
                            <option value=''>-PLEASE SELECT-</option>
                            {renderFinancingCompanySelect()}
                    </select></p>
                    <br/>
                    <br/>
                    {financingCompanyId < 1 ? 
                      <p style={{textAlign:'center'}}><Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => showNewFinancingCompany()}>Financing Company not listed?</Button></p>
                    :
                      <p style={{textAlign:'center'}}><Button onClick={() => submitVehicleFinancingCompany()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>SUBMIT</Button></p>
                    }
                    <br/>
                    
                    <p style={{textAlign:'center'}}><Button onClick={() => hideUpdateFinancing()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>CANCEL</Button></p>
                  </div>
                </div>
              : undefined}
              {show_update_name ? 
                <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', justifyContent:'center', alignItems:'center', zIndex:100}}>
                  <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                    <h2 style={{textAlign:'center', fontWeight:'bold'}}>Update Vehicle Name</h2>
                    <p style={{textAlign:'center'}}>New name:<br/><input type='text' onChange={e => updateName(e.target.value)} value={name} defaultValue={vehicle['resp'][0]['name']} /></p>
                    <br/>
                    <p style={{textAlign:'center'}}><Button onClick={() => confirmUpdateName()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>SUBMIT</Button></p>
                    <p style={{textAlign:'center'}}><Button onClick={() => cancelUpdateName()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>CANCEL</Button></p>
                  </div>
                </div>
              : undefined}
              {show_update_vin ? 
                <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', justifyContent:'center', alignItems:'center', zIndex:100}}>
                  <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                    <h2 style={{textAlign:'center', fontWeight:'bold'}}>Update VIN</h2>
                    <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}><b>Be very careful with this tool.</b> OEM integration requires the correct VIN. Do not enter the wrong VIN.</p>
                    <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>TEXTFIELD VIN</p>
                    <br/>
                    <p style={{textAlign:'center'}}><Button onClick={() => confirmUpdateVin()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>SUBMIT</Button></p>
                    <p style={{textAlign:'center'}}><Button onClick={() => cancelUpdateVin()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>CANCEL</Button></p>
                  </div>
                </div>
              : undefined}
              {/*show_update_color ? 
                <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', justifyContent:'center', alignItems:'center', zIndex:100}}>
                  <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                    <h2 style={{textAlign:'center', fontWeight:'bold'}}>Update Color</h2>
                    <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>Select the vehicles new color:</p>
                    <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>SELECTFIELD COLOR</p>
                    <br/>
                    {color===''?undefined:<p style={{textAlign:'center'}}><Button onClick={() => confirmUpdateColor()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>SUBMIT</Button></p>}
                    <p style={{textAlign:'center'}}><Button onClick={() => cancelUpdateColor()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>CANCEL</Button></p>
                  </div>
                </div>
              : undefined*/}
              {show_confirm ? 
                <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', justifyContent:'center', alignItems:'center', zIndex:100}}>
                  <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                    <h2 style={{textAlign:'center', fontWeight:'bold'}}>Are you sure?</h2>
                    <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>{confirm_text}</p>
                    <p style={{textAlign:'center'}}><Button onClick={() => confirm()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>CONFIRM</Button></p>
                    <p style={{textAlign:'center'}}><Button onClick={() => cancelConfirmation()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>CANCEL</Button></p>
                  </div>
                </div>
              : undefined}
              {show_error_handler ? 
                <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', justifyContent:'center', alignItems:'center', zIndex:100}}>
                  <div style={{position:'fixed', top:'20%', left:'50%',width:'600px',marginLeft:'-300px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                    <h2 style={{textAlign:'center', fontWeight:'bold'}}>Whoops!</h2>
                    <p style={{textAlign:'center'}}>There was an error processing your request.</p>
                    <p style={{textAlign:'center'}}>{error_message}</p>
                    <p style={{textAlign:'center'}}><Button onClick={() => hideErrorHandler()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>EXIT</Button></p>
                  </div>
                </div>
              : undefined}
            </Flex>
        }
      </View>
    </Flex>
  );
}
