import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Flex, Divider, View, Text, Heading, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TableContainer from '../components/tablecontainer';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { FaQuestionCircle, FaAngleDoubleRight, FaAngleDoubleDown } from 'react-icons/fa';
import { PieChart } from 'react-minimal-pie-chart';
import Loading from '../components/loading';
import Fleetselector from "../components/fleetselector";

export default function Fleetwidesummary() {
  let params = useParams();
  const [displayJson, updateDisplayJson] = React.useState(false);
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"Loading...",rows:[]})
  const [count, setCount] = useState(0);
  const [selectedObjRangeStart, updateSelectedObjRangeStart] = useState("")
  const [selectedObjRangeEnd, updateSelectedObjRangeEnd] = useState("")
  const [selectedObjRangeStartFull, updateSelectedObjRangeStartFull] = useState("")
  const [selectedObjRangeEndFull, updateSelectedObjRangeEndFull] = useState("")
  const [fleet_codes, updateFleetCodes] = React.useState(null);
  const [selected_fleet, updateSelectedFleet] = React.useState(false);



  const tableCols = [
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <FaAngleDoubleDown /> : <FaAngleDoubleRight />}
        </span>
      ),
    },
  {
      Header: 'Name',
      accessor: 'car_name',
  },
  {
      Header: 'VIN',
      accessor: 'car_vin',
  },
  {
      Header: 'License Plate',
      accessor: 'car_license',
  },
  {
      Header: 'Total Costs',
      accessor: 'total_cost',
  }
]

  function toggleDisplayJson()
  {
    if(displayJson)
    {
      updateDisplayJson(false);
    } else {
      updateDisplayJson(true);
    }
  }
  

  async function fetchData(tSelectedFleet) {
    updateIsLoading(true)
    var thisFleet = false
    if(!tSelectedFleet)
    {
        thisFleet = selected_fleet
    } else {
        if(tSelectedFleet==="")
        {
            thisFleet = selected_fleet
        } else {
            thisFleet = tSelectedFleet
        }
    }
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    var myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND,
        jwt:jwt,
        api_endpoint:"fleetwidesummary",
        request_type:"GET",
        range_start: selectedObjRangeStart, 
        range_end: selectedObjRangeEnd
    }};
    if(thisFleet)
    {
        myInit = {queryStringParameters:{ 
            back_end: process.env.REACT_APP_BACKEND,
            jwt:jwt,
            api_endpoint:"fleetwidesummary",
            request_type:"GET",
            range_start: selectedObjRangeStart, 
            range_end: selectedObjRangeEnd, 
            fleet_code:thisFleet
        }};
    }
    //console.log(JSON.stringify(myInit));
    try {
        const res = await API.get('adminWebTools','/ago',myInit);
        updateRes(res);
        updateFleetCodes(res.fleet_codes)
        updateSelectedFleet(res.fleet_code)
        //console.log(res);
        updateIsLoading(false)
        return
    }
    catch (e) {
        var statusString = "Error: " + e;
        const res = {status:statusString,rows:[]};
        updateRes(res);
        updateIsLoading(false)
        return
    }
  }
  useEffect(() => {
    fetchData();
  },[count])


  async function selectNewFleet(val)
  {
      updateSelectedFleet(val)
      fetchData(val)
  }
  //var PageNoData = <View><p>{JSON.stringify(res)}</p></View>
  //var MustRefresh = <View><p>Please get updates to display table</p></View>

  
  function handleRangeStartSelected(date)
  {
      updateSelectedObjRangeStartFull(date);
      const date2 = date.toISOString().substring(0, 10);
      updateSelectedObjRangeStart(date2);
  }
  function handleRangeEndSelected(date)
  {
      updateSelectedObjRangeEndFull(date);
      const date2 = date.toISOString().substring(0, 10);
      updateSelectedObjRangeEnd(date2);
  }

  function formatDate(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    //var min = a.getMinutes();
    //var sec = a.getSeconds();
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    var day = days[a.getDay()];
    if(hour > 12)
    {
      var thour = hour - 12
      var pmam = ' PM';
    } else {
      if(hour < 12)
      {
        if(hour < 1)
        {
          var thour = '12';
          var pmam = ' AM';
        } else {
          var thour = hour;
          var pmam = ' AM';
        }
      } else {
        var thour = '12';
        var pmam = ' PM';
      }
    }
    var time = day + ', ' + month + ' ' + date + ', ' + year + ' - ' + thour + pmam;
    return time;
  }

{
    /* TO DO -
        var DateRangeParams = <View>
            <p>Enter earliest date</p>
            <DatePicker
                name="Range Start"
                selected={selectedObjRangeStartFull}
                onChange={(date) => handleRangeStartSelected(date)}
                showTimeSelect
                dateFormat="yyyy-MM-d"
                timeIntervals={60}
                />
            <br/><br/>
            <p>Enter latest date</p>
            <DatePicker
                name="Range End"
                selected={selectedObjRangeEndFull}
                onChange={(date) => handleRangeEndSelected(date)}
                showTimeSelect
                dateFormat="yyyy-MM-d"
                timeIntervals={60}
                />
            <br/>
        </View>
    */
}


var NoData = <Flex 
direction='column'
> 
<Heading level={1}>Fleetwide Summary</Heading>
<Text style={{width:'100%'}}>loading...</Text>
</Flex>

  return (
    <Flex   //  https://ui.docs.amplify.aws/react/components/flex#demo
        direction='column'      //direction={{ base: 'column', large: 'row' }}
        //justifyContent='normal'           justifyContent={{ base: 'flex-start', large: 'flex-start' }}        //justifyContent="center"
        //alignItems='stretch'              alignItems={{ base: 'stretch', large: 'flex-start' }}       //alignItems="center" // alignItems="flex-end" (right)
        //alignContent='normal'             alignContent="flex-start"       //alignContent="center"
        wrap="wrap" //nowrap
        gap="1rem"
    >
        <View style={{padding:"1rem"}}>
            {
                is_loading ? <Loading /> : 
                typeof(res) === 'undefined' ? NoData :
                typeof(res.resp) === 'undefined' ? NoData : 
                typeof(res.resp[0]) === 'undefined' ?  NoData :
                <Flex 
                    direction='column'
                > 
                    <Heading level={1}>Fleetwide Summary</Heading>
                    <Text style={{width:'100%'}}>We've analyzed the data for every vehicle in your fleet. Here's a breakdown of your portfolio:</Text>
                    <View style={{maxWidth:'600px'}}>
                        <Table
                            caption=""
                            highlightOnHover={false}>
                            <TableHead>
                                <TableRow>
                                    <TableCell as="th">Period Start</TableCell>
                                    <TableCell as="th">Period End</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{formatDate(res.resp[0]['period_start'])}</TableCell>
                                    <TableCell>{formatDate(res.resp[0]['period_end'])}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </View>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Flex wrap="wrap" justifyContent="center" gap="1rem">
                        <Flex direction="column">
                            <Divider />
                            <Fleetselector fleet_codes={fleet_codes} selected_fleet={selected_fleet} updateParentSelectedFleet={selectNewFleet}/>
                            <Heading level={2}>Fleet Metrics</Heading>
                            <Flex wrap="wrap" justifyContent="center" gap="1rem">
                                <Flex direction="column">
                                    <Divider />
                                    <Heading level={4}>Fleet Utilization</Heading>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Data Point</TableCell>
                                                <TableCell>Most Recent</TableCell>
                                                <TableCell>Prior Week</TableCell>
                                                <TableCell>Change</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>insured vehicles count:</TableCell>
                                                <TableCell>{res.resp[0]['recent_insured_cars_count']}</TableCell>
                                                <TableCell>{res.resp[0]['last_week_insured_cars_count']}</TableCell>
                                                <TableCell>{res.resp[0]['change_insured_cars_count']}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>total miles:</TableCell>
                                                <TableCell>{isNaN(res.resp[0]['recent_total_miles']) ? res.resp[0]['recent_total_miles'] : res.resp[0]['recent_total_miles'].toLocaleString("en-US")}</TableCell>
                                                <TableCell>{isNaN(res.resp[0]['last_week_total_miles']) ? res.resp[0]['last_week_total_miles'] : res.resp[0]['last_week_total_miles'].toLocaleString("en-US")}</TableCell>
                                                <TableCell>{isNaN(res.resp[0]['change_total_miles']) ? res.resp[0]['change_total_miles'] : res.resp[0]['change_total_miles'].toLocaleString("en-US")}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>total driving hours:</TableCell>
                                                <TableCell>{res.resp[0]['recent_total_hours']}</TableCell>
                                                <TableCell>{res.resp[0]['last_week_total_hours']}</TableCell>
                                                <TableCell>{res.resp[0]['change_total_hours']}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Flex>
                                <Flex direction="column">
                                    <Divider />
                                    <Heading level={4}>Fleet Insurance Costs</Heading>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Data Point</TableCell>
                                                <TableCell>Most Recent</TableCell>
                                                <TableCell>Prior Week</TableCell>
                                                <TableCell>Change</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>total liability dollars:</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['recent_total_liability_dollars']) ? res.resp[0]['recent_total_liability_dollars'] : res.resp[0]['recent_total_liability_dollars'].toLocaleString("en-US")}</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['last_week_total_liability_dollars']) ? res.resp[0]['last_week_total_liability_dollars'] : res.resp[0]['last_week_total_liability_dollars'].toLocaleString("en-US")}</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['change_total_liability_dollars']) ? res.resp[0]['change_total_liability_dollars'] : res.resp[0]['change_total_liability_dollars'].toLocaleString("en-US")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>per vehicle total liability dollars:</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['recent_per_car_total_liability_dollars']) ? res.resp[0]['recent_per_car_total_liability_dollars'] : res.resp[0]['recent_per_car_total_liability_dollars'].toLocaleString("en-US")}</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['last_week_per_car_total_liability_dollars']) ? res.resp[0]['last_week_per_car_total_liability_dollars'] : res.resp[0]['last_week_per_car_total_liability_dollars'].toLocaleString("en-US")}</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['change_per_car_total_liability_dollars']) ? res.resp[0]['change_per_car_total_liability_dollars'] : res.resp[0]['change_per_car_total_liability_dollars'].toLocaleString("en-US")}</TableCell>
                                            </TableRow>
                                            <TableRow><TableCell>total physical damage dollars:</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['recent_total_physical_damage_dollars']) ? res.resp[0]['recent_total_physical_damage_dollars'] : res.resp[0]['recent_total_physical_damage_dollars'].toLocaleString("en-US")}</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['last_week_total_physical_damage_dollars']) ? res.resp[0]['last_week_total_physical_damage_dollars'] : res.resp[0]['last_week_total_physical_damage_dollars'].toLocaleString("en-US")}</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['change_total_physical_damage_dollars']) ? res.resp[0]['change_total_physical_damage_dollars'] : res.resp[0]['change_total_physical_damage_dollars'].toLocaleString("en-US")}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell>per vehicle total physical damage dollars:</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['recent_per_car_total_physical_damage_dollars']) ? res.resp[0]['recent_per_car_total_physical_damage_dollars'] : res.resp[0]['recent_per_car_total_physical_damage_dollars'].toLocaleString("en-US")}</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['last_week_per_car_total_physical_damage_dollars']) ? res.resp[0]['last_week_per_car_total_physical_damage_dollars'] : res.resp[0]['last_week_per_car_total_physical_damage_dollars'].toLocaleString("en-US")}</TableCell>
                                                <TableCell>${isNaN(res.resp[0]['change_per_car_total_physical_damage_dollars']) ? res.resp[0]['change_per_car_total_physical_damage_dollars'] : res.resp[0]['change_per_car_total_physical_damage_dollars'].toLocaleString("en-US")}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                </Flex>
                                <Flex direction="column">
                                    <Divider />
                                    <Heading level={4}>Gig vs. Personal Miles</Heading>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Data Point</TableCell>
                                                <TableCell>Most Recent</TableCell>
                                                <TableCell>Prior Week</TableCell>
                                                <TableCell>Change</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>percent liability miles not gig insured:</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_liability_miles_not_gig_insured'])}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_liability_miles_not_gig_insured'])}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_liability_miles_not_gig_insured'])}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>percent physical damage miles not gig insured:</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_physical_damage_miles_not_gig_insured'])}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_physical_damage_miles_not_gig_insured'])}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_physical_damage_miles_not_gig_insured'])}%</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Flex>
                            </Flex>
                            <Divider />
                        </Flex>
                        <Flex direction="column">
                            <Divider />
                            <Heading level={2}>Safety Bucket Breakdown</Heading>
                            <Flex wrap="wrap" justifyContent="center" gap="1rem">
                                <Flex direction="column">
                                    <Divider />
                                    <Heading level={4}>Vehicle Count</Heading>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Data Point</TableCell>
                                                <TableCell>Most Recent</TableCell>
                                                <TableCell>Prior Week</TableCell>
                                                <TableCell>Change</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>super safe vehicle count:  </TableCell>
                                                <TableCell>{res.resp[0]['recent_super_safe_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['last_week_super_safe_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['change_super_safe_car_count']}</TableCell>
                                            </TableRow>
                                            <TableRow><TableCell>safe vehicle count:  </TableCell>
                                                <TableCell>{res.resp[0]['recent_safe_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['last_week_safe_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['change_safe_car_count']}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>regular vehicle count:  </TableCell>
                                                <TableCell>{res.resp[0]['recent_regular_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['last_week_regular_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['change_regular_car_count']}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>risky vehicle count:  </TableCell>
                                                <TableCell>{res.resp[0]['recent_risky_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['last_week_risky_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['change_risky_car_count']}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>super risky vehicle count:  </TableCell>
                                                <TableCell>{res.resp[0]['recent_super_risky_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['last_week_super_risky_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['change_super_risky_car_count']}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>statistically insignificant vehicle count:  </TableCell>
                                                <TableCell>{res.resp[0]['recent_statistically_insignificant_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['last_week_statistically_insignificant_car_count']}</TableCell>
                                                <TableCell>{res.resp[0]['change_statistically_insignificant_car_count']}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Flex>
                                <Flex direction="column">
                                    <Divider />
                                    <Heading level={4}>Percent of Fleet</Heading>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Data Point</TableCell>
                                                <TableCell>Most Recent</TableCell>
                                                <TableCell>Prior Week</TableCell>
                                                <TableCell>Change</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>percent super safe vehicles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_super_safe_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_super_safe_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_super_safe_car_count'],0)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>percent safe vehicles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_safe_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_safe_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_safe_car_count'],0)}%</TableCell>
                                            </TableRow>
                                            <TableRow><TableCell>percent regular vehicles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_regular_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_regular_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_regular_car_count'],0)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>percent risky vehicles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_risky_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_risky_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_risky_car_count'],0)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>percent super risky vehicles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_super_risky_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_super_risky_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_super_risky_car_count'],0)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>percent statistically insignificant vehicles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_statistically_insignificant_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_statistically_insignificant_car_count'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_statistically_insignificant_car_count'],0)}%</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Flex>
                                <Flex direction="column">
                                    <Divider />
                                    <Heading level={4}>Percent of Miles</Heading>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Data Point</TableCell>
                                                <TableCell>Most Recent</TableCell>
                                                <TableCell>Prior Week</TableCell>
                                                <TableCell>Change</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>percent super safe miles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_miles_super_safe'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_miles_super_safe'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_miles_super_safe'],0)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>percent safe miles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_miles_safe'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_miles_safe'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_miles_safe'],0)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>percent regular miles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_miles_regular'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_miles_regular'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_miles_regular'],0)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>percent risky miles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_miles_risky'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_miles_risky'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_miles_risky'],0)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>percent super risky miles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_miles_super_risky'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_miles_super_risky'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_miles_super_risky'],0)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>percent statistically insignificant miles:  </TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['recent_percent_miles_stat_insig'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['last_week_percent_miles_stat_insig'],0)}%</TableCell>
                                                <TableCell>{Math.round(100*res.resp[0]['change_percent_miles_stat_insig'],0)}%</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Flex>
                            </Flex>
                            <Divider />
                        </Flex>
                        <Flex direction="column">
                            <Divider />
                            <Heading level={2}>Risky Driving Events</Heading>
                            <Flex direction="column">
                                <Heading level={3}>Speeding</Heading>
                                <Flex wrap="wrap" justifyContent="center" gap="1rem">
                                    <Flex direction="column">
                                        <Divider />
                                        <Heading level={4}>Speeding 90-95mph</Heading>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Data Point</TableCell>
                                                    <TableCell>Most Recent</TableCell>
                                                    <TableCell>Prior Week</TableCell>
                                                    <TableCell>Change</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>90-95 speeding events per 1000 miles:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_speeding_events_per_1000_miles_tier_1']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_speeding_events_per_1000_miles_tier_1']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_speeding_events_per_1000_miles_tier_1']}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>90-95 speeding events per 100 hours:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_speeding_events_per_100_hours_tier_1']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_speeding_events_per_100_hours_tier_1']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_speeding_events_per_100_hours_tier_1']}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Flex>
                                    <Flex direction="column">
                                        <Divider />
                                        <Heading level={4}>Speeding per 95-100mph</Heading>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Data Point</TableCell>
                                                    <TableCell>Most Recent</TableCell>
                                                    <TableCell>Prior Week</TableCell>
                                                    <TableCell>Change</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>95-100 speeding events per 1000 miles:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_speeding_events_per_1000_miles_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_speeding_events_per_1000_miles_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_speeding_events_per_1000_miles_tier_2']}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>95-100 speeding events per 100 hours:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_speeding_events_per_100_hours_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_speeding_events_per_100_hours_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_speeding_events_per_100_hours_tier_2']}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Flex>
                                    <Flex direction="column">
                                        <Divider />
                                        <Heading level={4}>Speeding 100+mph</Heading>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Data Point</TableCell>
                                                    <TableCell>Most Recent</TableCell>
                                                    <TableCell>Prior Week</TableCell>
                                                    <TableCell>Change</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>100+ speeding events per 1000 miles:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_speeding_events_per_1000_miles_tier_3']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_speeding_events_per_1000_miles_tier_3']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_speeding_events_per_1000_miles_tier_3']}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>100+ speeding events per 100 hours:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_speeding_events_per_100_hours_tier_3']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_speeding_events_per_100_hours_tier_3']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_speeding_events_per_100_hours_tier_3']}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex direction="column">
                                <Heading level={3}>Hard Braking / Acceleration</Heading>
                                <Flex wrap="wrap" justifyContent="center" gap="1rem">
                                    <Flex direction="column">
                                        <Divider />
                                        <Heading level={4}>Hard Braking (over 10 mph per second)</Heading>
                                        <Table>
                                            <TableHead> 
                                                <TableRow>
                                                    <TableCell>Data Point</TableCell>
                                                    <TableCell>Most Recent</TableCell>
                                                    <TableCell>Prior Week</TableCell>
                                                    <TableCell>Change</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>severe hard brake events per 1000 miles:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_hard_brake_events_per_1000_miles_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_hard_brake_events_per_1000_miles_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_hard_brake_events_per_1000_miles_tier_2']}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>severe hard brake events per 100 hours:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_hard_brake_events_per_100_hours_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_hard_brake_events_per_100_hours_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_hard_brake_events_per_100_hours_tier_2']}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Flex>
                                    <Flex direction="column">
                                        <Divider />
                                        <Heading level={4}>Hard Acceleration (over 10 mph per second)</Heading>
                                        <Table>
                                            <TableHead> 
                                                <TableRow>
                                                    <TableCell>Data Point</TableCell>
                                                    <TableCell>Most Recent</TableCell>
                                                    <TableCell>Prior Week</TableCell>
                                                    <TableCell>Change</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>severe hard accelerate events per 1000 miles:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_hard_accelerate_events_per_1000_miles_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_hard_accelerate_events_per_1000_miles_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_hard_accelerate_events_per_1000_miles_tier_2']}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>severe hard accelerate events per 100 hours:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_hard_accelerate_events_per_100_hours_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_hard_accelerate_events_per_100_hours_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_hard_accelerate_events_per_100_hours_tier_2']}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex direction="column">
                                <Heading level={3}>Tired Driving</Heading>
                                <Flex wrap="wrap" justifyContent="center" gap="1rem">

                                    <Flex direction="column">
                                        <Divider />
                                        <Heading level={4}>Tired Driving 16-20 hours</Heading>
                                        <Table>
                                            <TableHead> 
                                                <TableRow>
                                                    <TableCell>Data Point</TableCell>
                                                    <TableCell>Most Recent</TableCell>
                                                    <TableCell>Prior Week</TableCell>
                                                    <TableCell>Change</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>16-20 tired events per 1000 miles:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_tired_events_per_1000_miles_tier_1']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_tired_events_per_1000_miles_tier_1']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_tired_events_per_1000_miles_tier_1']}</TableCell>
                                                </TableRow>
                                                <TableRow style={{borderBottom:'1px dashed black'}}>
                                                    <TableCell>16-20 tired events per 100 hours:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_tired_events_per_100_hours_tier_1']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_tired_events_per_100_hours_tier_1']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_tired_events_per_100_hours_tier_1']}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Flex>
                                    <Flex direction="column">
                                        <Divider />
                                        <Heading level={4}>Tired Driving 20+ hours</Heading>
                                        <Table>
                                            <TableHead> 
                                                <TableRow>
                                                    <TableCell>Data Point</TableCell>
                                                    <TableCell>Most Recent</TableCell>
                                                    <TableCell>Prior Week</TableCell>
                                                    <TableCell>Change</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>20+ tired events per 1000 miles:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_tired_events_per_1000_miles_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_tired_events_per_1000_miles_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_tired_events_per_1000_miles_tier_2']}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>20+ tired events per 100 hours:  </TableCell>
                                                    <TableCell>{res.resp[0]['recent_tired_events_per_100_hours_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['last_week_tired_events_per_100_hours_tier_2']}</TableCell>
                                                    <TableCell>{res.resp[0]['change_tired_events_per_100_hours_tier_2']}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Flex>
                                    <Flex direction="column">
                                        <Divider />
                                        <Heading level={4}>Late Driving</Heading>
                                        <Table>
                                            <TableHead> 
                                                <TableRow>
                                                    <TableCell>Data Point</TableCell>
                                                    <TableCell>Most Recent</TableCell>
                                                    <TableCell>Prior Week</TableCell>
                                                    <TableCell>Change</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>% late night hours (1am - 4am):  </TableCell>
                                                    <TableCell>{Math.round(100*res.resp[0]['recent_percent_late_night_hours_median'],0)}%</TableCell>
                                                    <TableCell>{Math.round(100*res.resp[0]['last_week_percent_late_night_hours_median'],0)}%</TableCell>
                                                    <TableCell>{Math.round(100*res.resp[0]['change_percent_late_night_hours_median'],0)}%</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Divider />
                        </Flex>
                    </Flex>
                    <br/><br/><br/>
                </Flex>
            }
        </View>
    </Flex>
  );
}