import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import Loading from '../components/loading';
import { Flex, Divider, View, Text, Heading, Button } from '@aws-amplify/ui-react';
import TableContainer from '../components/tablecontainer';
import { FaAngleDoubleRight, FaAngleDoubleDown } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip'

export default function Billingentry() {
  let params = useParams();
  const [res, updateRes] = useState({resp:[{'BilLStTstamp':'loading...', 'BillEndTstamp':'loading...'}]})
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const tableCols = [
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <FaAngleDoubleDown /> : <FaAngleDoubleRight />}
        </span>
      ),
    },
    {
        Header: 'Analysed Date',
        accessor: 'AnalyisTstamp',
    },
    {
        Header: 'Insured Cars',
        accessor: 'InsuredCarCount',
    },
    {
        Header: 'Total Miles',
        accessor: 'MlsTot',
    },
    {
        Header: 'Total $ Per Car',
        accessor: 'Tot$PerCar',
    },
    {
        Header: 'Total $ With Safety Adjust',
        accessor: 'Tot$SafeAdj',
    },
    {
        Header: 'Total $ Without Safety Adjust',
        accessor: 'Tot$NoSafeAdj',
    }
]

useEffect(() => {
  const fetchBillingentry = async () => {
    updateIsLoading(true)
    let billing_period_start_end = params.billingPeriodStartEnd
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND, 
      "api_endpoint":"billinghistory",
      "jwt":jwt,
      "request_type":"GET",
      "billing_period_start_end": billing_period_start_end
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit);
        //console.log(JSON.stringify(res))
        updateRes(res);
        updateIsLoading(false)
        return
    }
    catch (e) {
        const res = {status:"Error - email us at info@joinago.com",resp:[]};
        updateRes(res);
        updateIsLoading(false)
        return
    }
  }
  fetchBillingentry()
},[count,params])

var NoData = <p>No data to display.</p>

return (
  <Flex   //  https://ui.docs.amplify.aws/react/components/flex#demo
      direction='column'      //direction={{ base: 'column', large: 'row' }}
      //justifyContent='normal'           justifyContent={{ base: 'flex-start', large: 'flex-start' }}        //justifyContent="center"
      //alignItems='stretch'              alignItems={{ base: 'stretch', large: 'flex-start' }}       //alignItems="center" // alignItems="flex-end" (right)
      //alignContent='normal'             alignContent="flex-start"       //alignContent="center"
      wrap="wrap" //nowrap
      gap="1rem"
  >
      <View style={{padding:"1rem"}}>
          {
            is_loading ? <Loading /> : 
            <Flex 
                direction='column'
            >
                <Heading level={1}>Billing Entry Versions</Heading>
                <Flex direction='column'>
                  {typeof(res.resp) === 'undefined' ?  <Text style={{width:'100%'}}>Loading...</Text> : 
                  <Text style={{width:'100%'}}>Below is a history of the changes for billing period {res.resp[0]['BilLStTstamp']} - {res.resp[0]['BillEndTstamp']}.</Text>}
                  <View>
                      <Button onClick={() => setCount(count + 1)}>
                          PRESS TO GET LATEST UPDATES
                      </Button>
                  </View>
                  <Divider />
                  <TableContainer columns={tableCols} data = {res.resp} defaultSort = 'AnalyisTstamp' renderRowSubComponent={row => {
                    return (
                      <View style={{padding:"20px"}}>
                          <Heading level={2}>Details</Heading>
                          <table>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Created:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['created']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Fleet ID:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['FleetID']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Analysed:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['AnalyisTstamp']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Billing Period Start:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['BilLStTstamp']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Billing Period End:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['BillEndTstamp']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Insured Cars Count:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['InsuredCarCount']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Total Miles:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['MlsTot']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Liability $ No Safety Adjust:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['Liab$NoSafeAdj']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Liability $ Safety Adjust:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['Liab$SafeAdj']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Liability $ Per Car:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['Liab$PerCar']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Cents Per Liability Mile:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['CentsPerLiabMile']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Liability Gig Insured Miles:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['LiabGigInsMls']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Liability Gig Uninsured Miles:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['LiabGigUninsMls']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Liability Personal Miles:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['LiabPersMls']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Physical Damage $ No Safety Adjust:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDam$NoSafeAdj']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Physical Damage $ Safety Adjust:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDam$SafeAdj']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Physical Damage $ Per Car:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDam$PerCar']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Cents Per Physical Damage Mile:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['CentsPerPhysDamMile']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Physical Damage Gig Insured Miles:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDamGigInsMls']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Physical Damage Gig Uninsured Miles:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDamGigUninsMls']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Physical Damage Personal Miles:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDamPersMls']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Total $ Per Car:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['Tot$PerCar']}</td>
                            </tr>
                            <tr>
                              <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Total $ No Safety Adjust:  </th>
                              <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['Tot$NoSafeAdj']}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}>Total $ Safety Adjust:  </th>
                              <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['Tot$SafeAdj']}</td>
                            </tr>
                          </table>
                          <br/>
                      </View>
                    );
                  }}/>
                  <ReactTooltip />
                </Flex>
                <Divider />
            </Flex>
          }
      </View>
  </Flex>
  );
}

